import React, { useEffect, useState } from 'react';
import './App.css';

function App() {
  const [token, setToken] = useState('');
  const [buttonText, setButtonText] = useState("Enter Davide's Realm");
  const [isLighting, setIsLighting] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    // Parse the token from the URL query string
    const urlParams = new URLSearchParams(window.location.search);
    const tokenParam = urlParams.get('token');
    if (tokenParam) {
      setToken(tokenParam);
    }
  }, []);

  useEffect(() => {
    // Update the query params when the token changes
    const url = new URL(window.location);
    if (token) {
      url.searchParams.set('token', token);
    } else {
      url.searchParams.delete('token');
    }
    window.history.replaceState({}, '', url);
  }, [token]);

  const handleClick = async () => {
    if (!token || isDisabled) {
      console.error('No token provided or button is disabled');
      return;
    }

    setButtonText('Door opening!');
    setIsLighting(true);
    setIsDisabled(true);

    try {
      const response = await fetch(`https://webhook.ddns.scaccoman.com/api/webhook/webhook-nuki-intercom-open?token=${token}`, {
        method: 'POST',
      });
      if (response.ok) {
        console.log('Request successful');
      } else {
        console.error('Request failed');
      }
    } catch (error) {
      console.error('Error:', error);
    }

    setTimeout(() => {
      setButtonText("Enter Davide's Realm");
      setIsLighting(false);
      setIsDisabled(false);
    }, 10000);
  };

  return (
    <div className={`App ${isLighting ? 'lighting' : ''}`}>
      <div className="input-container">
        <label htmlFor="token-input" className="token-label">
          Door Unlock Token
        </label>
        <input 
          id="token-input"
          type="text" 
          value={token} 
          onChange={(e) => setToken(e.target.value)} 
          placeholder="Please ask Davide" 
          className="token-input"
          disabled={isDisabled}
        />
      </div>
      <button 
        className={`round-button ${isLighting ? 'lighting' : ''}`} 
        onClick={handleClick}
        disabled={isDisabled}
      >
        {buttonText}
      </button>
      <p className="note">
        Please pull down the door handle when you hear the buzzer sound, you will only have 10 seconds
      </p>
    </div>
  );
}

export default App;
